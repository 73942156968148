<template>
	<trips-skeleton :hide='true'>
		<div v-for='el in 3' :key='el'>
			<div class='a-card'>
				<div class='i-card-wrap'>
					<div class='i-card-container'>
						<div class='i-card-image'>
							<span class='skeleton' :style='`width: 100%; height: 63px;`'></span>
						</div>
						<div class='i-card-content'>
							<p class='i-card-title'><span class='skeleton' :style='`width: 70%; height: 24px; margin-bottom: 15px;`'></span></p>
							<ul class='a-list i-card-list' v-for='el in 2' :key='el'>
								<li class='a-list-item'>
									<span class='skeleton' :style='`width: 100%; height: 14px`'></span>
								</li>
								<li class='a-list-item'>
									<span class='skeleton' :style='`width: 168px; height: 14px`'></span>
								</li>
							</ul>
						</div>
					</div>
					<div class='i-card-action'>
						<div class='i-card-action-total'>
							<p class='a-card-total'><span class='skeleton' :style='`width: 130px; height: 25px`'></span></p>
						</div>
						<div class='i-card-action-group-buttons i-card-action-skeleton'>
							<span class='skeleton' :style='`width: 100%; height: 60px`'></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</trips-skeleton>
</template>

<script>
	import TripsSkeleton from '@/views/account/search/components/skeleton';

	export default {
		components: {
			TripsSkeleton
		}
	}
</script>
