<template>
	<div class='a-sidebar' :class='{"open": value}'>
		<div class="a-trips-mobile-header">
			<p class='a-trips-mobile-header-title'>{{$t("account.sidebar.filters")}}</p>
			<p class='app-link green--text' @click='$emit("clear-filters"), createSlider()' v-if='Object.keys(filters).length'>{{$t("account.buttons.cast")}}</p>
			<div class='a-trips-mobile-header-button ml-auto' @click='$emit("input", false)'>
				<v-icon icon='cross' size='17'></v-icon>
			</div>
		</div>

		<div class="a-sidebar-total">
			<div class="row align-items-center">
				<div class="col">
					<p class='a-sidebar-total-title' v-if='filtersItems.length !== items.length'>{{$t("account.sidebar.shown")}} {{ filtersItems.length }} {{$t("account.with")}} {{ items.length }} {{$t("account.sidebar.cars.cars")}}</p>
					<p class='a-sidebar-total-title' v-else>{{$t("account.sidebar.total_number_of_options")}} - {{ items.length }}</p>
				</div>
				<div class="col-auto tablet-hide">
					<v-button outline xxsmall color="green" class='a-sidebar-total-button'
						@click='$emit("clear-filters"), createSlider()'
						v-if='filtersItems.length !== items.length'
					>{{$t("account.buttons.cast_all")}}</v-button>
				</div>
			</div>
		</div>

		<div class='a-sidebar-items'>
			<div class="row">
				<div class="col">
					<p class='a-sidebar-title'>{{$t("account.sidebar.flights.travel_time")}}</p>
				</div>
			</div>

			<div v-for='(item, i) in sliders' :key='i'>
				<sidebar-slider
					:title='item.title'
					:min='item.min'
					:max='item.max'
					:value='item.value'
					:range='item.range'
					:currency='item.currency'
					:enable-cross="false"
					@change="item.value = $event, $emit('update-filters', {[item.key]: $event})"
					:reset='filters[item.key] && (item.range ? (filters[item.key][0] > item.min || filters[item.key][1] < item.max) : filters[item.key] < item.max)'
					@reset='$emit("reset-filters", [item.key]), item.value = (item.range ? [item.min, item.max] : item.max)'
				/>
			</div>
		</div>

		<div class='a-sidebar-mobile-footer'>
			<v-button xxsmall color='green white--text' class='a-card-mobile-btn' @click='$emit("input", false)'>
				{{$t('account.buttons.choose')}}
			</v-button>
			<p class='a-sidebar-mobile-footer-text' v-if='filtersItems.length !== items.length'>{{$t("account.sidebar.results_found", {length: filtersItems.length})}}</p>
		</div>
	</div>
</template>

<script>
	// import SidebarItems from '@/views/account/search/components/sidebar/items';
	import SidebarSlider from '@/views/account/search/components/sidebar/sliders';
	import _ from 'lodash'

	export default {
		data: () => ({
			sliders: []
		}),
		props: ["value", "items", "filters", "filtersItems"],
		computed: {
			source() {
				return JSON.parse(this.$route.query.source)
			}
		},
		components: {
			// SidebarItems,
			SidebarSlider
		},
		created() {
			this.createSlider();
		},
		methods: {
			createSlider() {
				this.sliders = [
					{
						key: "sliders.price",
						title: `${this.$t("account.sidebar.insurance.price_range")}`,
						value: [
							Math.ceil(_.minBy(_.flattenDeep(this.items), 'price').price),
							Math.ceil(_.maxBy(_.flattenDeep(this.items), 'price').price)
						],
						min: Math.ceil(_.minBy(_.flattenDeep(this.items), 'price').price),
						max: Math.ceil(_.maxBy(_.flattenDeep(this.items), 'price').price),
						currency: _.minBy(_.flattenDeep(this.items), 'price').currency,
						range: true
					}
				]
			},
			updateItems({key, name, func, total}) {
				return this.$_.uniqBy(this.items, key).map(t => {
					return {
						id: String(this.$_.get(t, key)),
						name: func ? func(this.$_.get(t, key)) : this.$_.get(t, (name ?? key)),
						total: total ? this.items.filter(el => el[key] === this.$_.get(t, key)).length : null,
						active: this.filters[key] && this.filters[key].find(el => el == String(this.$_.get(t, key))) ? true : false,
						disabled: !(this.filtersItems.find(el => this.$_.get(el, key) == this.$_.get(t, key)))
					}
				})
			},
			updateFilter(items, key) {
				this.$emit("update-filters", {[key]: items.filter(el => el.active && !el.all).map(el => el.id)})
			}
		},
		watch: {
			items() {
				this.createSlider();
			}
		}
	}
</script>
